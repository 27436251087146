import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../components/base';
import DxTable, { DxTableColumn } from '../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../components/container';
import { StoreLocationApi } from '../../context/api/config/storeLocationApi';
import { BaseSelectRequest } from '../../context/base/BaseSelectRequest';
import { StoreLocationModel } from '../../context/models/config/StoreLocationModel';
import { categoryActions } from '../../context/stores/category/categoryActions';
import { AppState } from '../../context/stores/rootReducers';
import { createUUID } from '../../utils/createUUID';
import PromotionDetail from './PromotionDetail';
import { PromotionModel } from '../../context/models/config/PromotionModel';
import { PromotionApi } from '../../context/api/config/promotionApi';
import { getImageByEnpoint } from '../../utils/slugHelper';

const mapStateToProps = (state: AppState) => ({
	titlePage: 'Category',
	categories: state.category.categories,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	handleReloadData: (request: BaseSelectRequest) => dispatch(categoryActions.fetchPaginationRequest(request)),
});

const PromotionTable = () => {
	const [promotions, setPromotions] = useState<PromotionModel[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [isShow, setShow] = useState<boolean>(false);
	const [loadingSave, setLoadingSave] = useState<boolean>(false);
	const [promotion, setPromotion] = useState<PromotionModel | undefined>();

	const getPromotionByAll = async () => {
		setLoading(true);
		setSelectedIds([]);
		try {
			const response = await PromotionApi.GetAllAsync();
			if (response?.isSuccess) {
				setPromotions(response.result);
			}
		} catch (_error) {
			setPromotions([]);
		} finally {
			setLoading(false);
		}
	};

	const handleSaveData = async (value: PromotionModel) => {
		setLoadingSave(true);
		try {
			const newValue = {
				...value,
				image: getImageByEnpoint(value?.image),
			};

			console.log('newValue', newValue);
			if (promotion) {
				await PromotionApi.PutAsync(newValue);
			} else {
				await PromotionApi.PostAsync(newValue);
			}
		} catch (_error) {
		} finally {
			setLoadingSave(false);
			setShow(false);
			getPromotionByAll();
			setSelectedIds([]);
		}
	};

	const handleDeletes = async () => {
		setLoading(true);
		try {
			if (selectedIds.length > 0) await PromotionApi.DeletesAsync(selectedIds);
		} catch (_error) {
		} finally {
			setLoading(false);
			getPromotionByAll();
			setSelectedIds([]);
		}
	};

	useEffect(() => {
		getPromotionByAll();
	}, []);

	// Cột của bảng
	const columns = useMemo((): any => {
		return [
			<DxTableColumn key={createUUID()} dataField='id' caption='ID' width={100} />,
			<DxTableColumn
				key={createUUID()}
				dataField='image'
				caption={`Ảnh`}
				width={100}
				fixed
				cellRender={(cell: any) => {
					const image = getImageByEnpoint(cell?.data?.image);
					return (
						<>
							<div className='user-card'>
								<div className='product-avatar bg-white border border-light d-none d-sm-block d-md-block'>
									<img src={image} alt='ảnh đại diện' />
								</div>
							</div>
						</>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='name' caption='Tiêu đề' minWidth={100} />,
			<DxTableColumn key={createUUID()} dataField='permalink' caption='Link' minWidth={200} />,
			<DxTableColumn
				key={createUUID()}
				width={50}
				caption='Sửa'
				alignment='center'
				cellRender={(cell: any) => (
					<ButtonLink
						onClick={() => {
							setPromotion(cell?.data);
							setShow(true);
						}}
						title='Sửa'
						icon='ni ni-edit'
						theme='link'
					/>
				)}
			/>,
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách chương trình khuyến mại`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							{/* <li>
								<Button
									text={`Xóa (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									onClick={() => handleDeletes()}
								/>
							</li> */}
							{/* <li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => {
										setShow(true);
										setPromotion(undefined);
									}}
								/>
							</li> */}
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{loading && <LoadingTable />}
						{!loading && (
							<DxTable
								dataSource={promotions}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={loading}
								defaultPageSize={10}
								height={window.innerHeight - 300}
								filters={{
									refreshDataGrid: getPromotionByAll,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										setSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
				{isShow && (
					<>
						<PromotionDetail
							isSaving={loadingSave}
							isShow={isShow}
							detailModel={promotion}
							onClose={() => setShow(false)}
							onSubmit={(data) => handleSaveData(data)}
							title={`${promotion ? `Cập nhật` : `Thêm mới`}`}
						/>
					</>
				)}
			</PageContainer>
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionTable);
