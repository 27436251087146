import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	NumberBox,
	TextBox,
} from '../../components/base';
import { ImageBox } from '../../components/base/ImageBox';
import { ConfigPartnerModel, dfConfigPartnerModel, validConfigPartnerSchema } from '../../context/models/config/PartnerModel';
import { dfStoreLocationModel, StoreLocationModel, validStoreLocationSchema } from '../../context/models/config/StoreLocationModel';

type IStoreLocationModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: StoreLocationModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: StoreLocationModel) => void;
};

const StoreLocationDetail = (props: IStoreLocationModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<StoreLocationModel>(dfStoreLocationModel);

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<StoreLocationModel>({
		//@ts-ignore
		resolver: yupResolver(validStoreLocationSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: StoreLocationModel) => {
		onSubmit(values);
	};

	console.log('VALUE', errors);

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên cửa hàng'
						/>
						<TextBox
							name={'address'}
							error={errors.address?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, address: value }));
							}}
							value={initialValues.address}
							hasValid={true}
							label='Đỉa chỉ trên map'
						/>
						<TextBox
							name={'phone_Number'}
							error={errors.phone_Number?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, phone_Number: value }));
							}}
							value={initialValues.phone_Number}
							hasValid={true}
							label='Số Điện Thoại'
						/>
						<TextBox
							name={'open_Hour'}
							error={errors.open_Hour?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, open_Hour: value }));
							}}
							value={initialValues.open_Hour}
							hasValid={true}
							label='Giờ mở cửa'
						/>
						<TextBox
							name={'close_Hour'}
							error={errors.close_Hour?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, close_Hour: value }));
							}}
							value={initialValues.close_Hour}
							hasValid={true}
							label='Giờ đóng cửa'
						/>
						<NumberBox
							name='lat'
							value={initialValues.lat}
							error={errors.lat?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, lat: value }));
							}}
							hasValid={true}
							sizeClass='sm'
							label='Lat'
						/>
						<NumberBox
							name='long'
							value={initialValues.long}
							error={errors.long?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, long: value }));
							}}
							hasValid={true}
							sizeClass='sm'
							label='Long'
						/>

						<ImageBox
							name='image'
							isDisabled={initialValues.name.length <= 0}
							file_name={initialValues.name}
							value={initialValues.image}
							onDataChanged={(image) => {
								setInitialValues((prev) => ({
									...prev,
									image: image?.path || '',
								}));
							}}
							hasValid={true}
							label='Ảnh'
						/>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={false}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default StoreLocationDetail;
