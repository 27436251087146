import { ApiBase } from '../../base/_ApiBase';
import { ApiClient } from '../../base/_ApiClient';
import { ProductChangeStatusRequest } from '../../models/products/ProductChangeStatusRequest';
import { ProductModel } from '../../models/products/ProductModel';
import { ProductSelectRequest } from '../../models/products/ProductSelectRequest';

export const ProductApi = new (class ProductApi extends ApiBase<ProductModel> {
	constructor({ baseUrl }: { baseUrl: string }) {
		super(baseUrl);
	}
	ChangeStatusAsync(request: ProductChangeStatusRequest) {
		return ApiClient.POST(`${this._baseUrl}/change-product-status`, request);
	}
	SelectPaginationByActionAsync(request: ProductSelectRequest) {
		const params: any = request;
		return ApiClient.GET(`${this._baseUrl}/select-by-action${request ? `?${new URLSearchParams(params).toString()}` : ''}`);
	}
	SelectPaginationByActionOwnerAsync(request: ProductSelectRequest) {
		const params: any = request;
		return ApiClient.GET(`${this._baseUrl}/select-by-owner-action${request ? `?${new URLSearchParams(params).toString()}` : ''}`);
	}
	SelectPaginationPublishedAsync(request: ProductSelectRequest) {
		const params: any = request;
		return ApiClient.GET(`${this._baseUrl}/select-published${request ? `?${new URLSearchParams(params).toString()}` : ''}`);
	}
	SelectProductImagesAsync(productId: number) {
		return ApiClient.GET(`${this._baseUrl}/select-product-images/${productId}`);
	}
})({ baseUrl: '/products' });
