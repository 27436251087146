import { AccountApi } from './accountApi';
import { ArticleApi } from './articles/articleApi';
import { ArticleCategoryApi } from './articles/articleCategoryApi';
import { BannerApi } from './banners/bannerApi';
import { BannerConfigApi } from './banners/bannerConfigApi';
import { BannerPositionApi } from './banners/bannerPositionApi';
import { CollectionApi } from './collections/collectionApi';
import { CollectionGroupApi } from './collections/collectionGroupApi';
import { CollectionItemApi } from './collections/collectionItemApi';
import { CollectionTypeApi } from './collections/collectionTypeApi';
import { PartnerApi } from './config/partnerApi';
import { KeywordSeoApi } from './crm/KeywordSeoApi';
import { MenuApi } from './menus/menuApi';
import { KeywordApi } from './products/keywordApi';
import { ProductApi } from './products/productApi';
import { ProductCategoryApi } from './products/productCategoryApi';
import { ProductImageApi } from './products/productImageApi';
import { ProductTypeApi } from './products/productTypeApi';
import { ProductTypeProductApi } from './products/productTypeProductApi';
import { RoleApi } from './users/roleApi';
import { UserApi } from './users/userApi';
import { UserGroupApi } from './users/userGroupApi';

export const rootApi = {
	account: AccountApi,
	user: UserApi,
	userGroup: UserGroupApi,
	menus: MenuApi,
	role: RoleApi,
	articleCategory: ArticleCategoryApi,
	article: ArticleApi,
	productCategory: ProductCategoryApi,
	banner: BannerApi,
	bannerPosition: BannerPositionApi,
	bannerConfig: BannerConfigApi,
	productImage: ProductImageApi,
	product: ProductApi,
	productType: ProductTypeApi,
	productTypeProduct: ProductTypeProductApi,
	keyword: KeywordApi,
	collection: CollectionApi,
	collectionType: CollectionTypeApi,
	collectionGroup: CollectionGroupApi,
	collectionItem: CollectionItemApi,
	keywordSeo: KeywordSeoApi,
	configPartner: PartnerApi,
};
