import React, { useMemo } from 'react';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';

interface IDateBoxProps {
	name: string;
	value?: string | number | Date;
	onValueChanged: (value?: any) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	minDate?: Date;
	maxDate?: Date;
	className?: string;
	typeDate?: 'datetime' | 'date' | 'time';
	displayFormat?: string;
	isViewMode?: 'dot_bottom' | 'line_bottom';
}

const defaultDate = '1900-01-01';

export const DxDateBox = (props: IDateBoxProps) => {
	const {
		value,
		error,
		name,
		label,
		sizeClass,
		isDisabled,
		helpBlock,
		labelSmall,
		hasValid,
		placeholder,
		minDate,
		maxDate,
		onValueChanged,
		className,
		displayFormat,
		typeDate,
		isViewMode,
	} = props;
	const showError = error ? true : false;

	const memoValue = useMemo(() => {
		let date;
		if (!value || !value.valueOf() || value === defaultDate) {
			date = '';
		} else if (typeof value === 'string') {
			if (value.toString().length <= 0) date = moment(defaultDate).format();
			date = moment(value).format();
		} else {
			date = value;
		}
		return date;
	}, [value]);

	const classBuild = useMemo(() => {
		let classBuild = `date-box form-control-${sizeClass || 'xs'}`;
		if (showError) classBuild = `${classBuild} error`;
		if (className) classBuild = `${classBuild} ${className}`;
		if (isViewMode) classBuild = `${classBuild} ${isViewMode}`;
		return classBuild;
	}, [sizeClass, showError, className, isViewMode]);

	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className='form-control-wrap'>
					<div className='form-icon form-icon-right'>
						<em className='icon ni ni-calendar-alt'></em>
					</div>
					<DateBox
						id={`date_input_${name}`}
						name={name}
						value={memoValue}
						min={minDate}
						max={maxDate}
						type={typeDate || 'date'}
						displayFormat={displayFormat || 'dd/MM/yyyy'}
						className={classBuild}
						disabled={isDisabled || (isViewMode && isViewMode.length > 0)}
						onValueChanged={(e) => {
							if (e.event) {
								const returnValue = e.value ? moment(e.value).format() : defaultDate;
								onValueChanged(returnValue);
							}
						}}
						placeholder={placeholder}
					/>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
